import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { PageContainer } from "../components/PageContainer";

const ScheduleCallContainer = styled(PageContainer)`
    width: 90%;
    max-width: 400px;
    padding: 20px;
    display: inline-block;
    margin: 30px auto;
    border-radius: 8px;
    `;

const CenterAlign = styled.div`
    text-align: center;
    `;

const Title = styled.h1`
    margin-top:0;
    `;


function ScheduleACallPage() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0)
    }
    , [])

    return (
        <CenterAlign>
            <ScheduleCallContainer>
                <form name="contact" method="POST" netlify className="ui form flex-column">
                    <Title>Schedule a Call</Title>
                    <input type="hidden" name="subject" 
                        value={"Contact Request from " + name} 
                    />
                    <input type="hidden" name="form-name" value="contact" />
                    <input
                        fluid
                        type="text"
                        placeholder="Name"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <br />
                    <input
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={{ width: '100%' }}
                    />
                    <br />
                    <textarea
                        placeholder="Message"
                        name = "message"
                        value={message}
                        rows="3"
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <br />
                    <button type="submit" className="ui button">Submit</button>
                </form>
            </ScheduleCallContainer>
        </CenterAlign>
    );
}

export default ScheduleACallPage;
