import React from 'react';

const FooterLogo = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src="/single-letter-logo.png" alt="Footer Logo" style={{ width: '200px' }} />
        </div>
    );
};

export default FooterLogo;

