import React from "react";
import styled from "styled-components";

const BaseFooterStyle = styled.footer`
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 20px 10px;
  bottom: 0;
  width: 100%;

  p {
    font-size: 12px;
    line-height: 8px;
  }
`

const FooterStyle = styled(BaseFooterStyle)`
  position: absolute;
`;

const Title = styled.h1`
  font-size: 14px;
`;

const FooterContent = () => {
  return (
    <>
      <Title>
        Bravo Concierge Physical Therapy
      </Title>
      <p>Email: <a href="mailto:jackie@bravoaustinpt.com">jackie@bravoaustinpt.com</a></p>
      <p>Instagram: <a href="https://www.instagram.com/bravoaustinpt/" target="_blank" rel="noreferrer"> @bravoaustinpt</a></p>
      <p>Phone: 512-222-7787</p>
      <p>Fax: 512-566-3042</p>
    </>
  );
}

const FooterSpacer = styled(BaseFooterStyle)`
  opacity: 0;
`;

const Footer = () => {
  return (
    <>
      {/* this is for scroll positioning */}
      <FooterSpacer>
        <FooterContent />
      </FooterSpacer>

      <FooterStyle>
        <FooterContent />
      </FooterStyle>
    </>
  );
}

export default Footer;