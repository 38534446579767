import React, { useEffect } from 'react';
import styled from 'styled-components';
import { PageContainer } from '../components/PageContainer';

const AboutPageContainer = styled(PageContainer)`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1100px;
    margin: auto;
`;

const HeaderImage = styled.img`
    max-width: 700px;
    margin-bottom: 32px;
    width: 100%;
    align-self: baseline;
`;

const Title = styled.h1`
    margin: 70px 0 32px;
`;

const BioBlurb = styled.p`
    line-height: 34.905px;
    margin-right: 20px;
`;

const Headshot = styled.img`
    margin-bottom: 20px;
    max-width: 75%;
    object-fit: cover;
`;

const Bio = () => { return (
    <BioBlurb>
        <p>Dr. Jackie West grew up in Massachusetts and participated in many sports and activities in high school and college, including colorguard and drum corps. Dealing with personal injury while at Massachusetts College of Pharmacy and Health Sciences as well as a love for science and teaching led her to pursue a career in physical therapy.</p>
        <p>Dr. West graduated from the Doctor of Physical Therapy program at the University of St. Augustine, Austin, in 2016 and completed her Fellowship in the American Academy of Orthopedic Manual Physical Therapists through The Manual Therapy Institute in 2021. Dr. West joined the University of St. Augustine as a member of the contributing faculty in the physical therapy program in 2022.</p>
        <p>Dr. West is passionate about helping patients get back to the activities they love and be able to achieve their goals. She finds that individualizing treatment and helping patients understand movement principles helps them get better faster.</p>
        <p>In her spare time, Dr. West enjoys traveling, watching New England sports and Formula 1, exercising, volunteering at Live Oak Unitarian Universalist Church, searching for the perfect waterfront relaxation spot in Austin, and spending time with her husband Luke, son Owen, and dog Tika.</p>
    </BioBlurb>
)}

const AboutPage = ({ isMobile }) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <AboutPageContainer>
            <HeaderImage src="/bravo-header.png" alt="bravo" />
            {!isMobile &&
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: '1' }}>
                        <Title>Dr. Jackie West, PT, DPT, FAAOMPT</Title>
                        <Bio />
                    </div>
                    <Headshot src="/headshot.png" alt="jackie" style={{ width: '400px' }} />
                </div>
            } {isMobile &&
                <>
                    <Title>Dr. Jackie West PT, DPT, FAAOMPT</Title>
                    <Headshot src="/headshot.png" alt="jackie" style={{ width: '400px' }} />
                    <Bio />
                </>
            }
        </AboutPageContainer>
    );
};

export default AboutPage;
