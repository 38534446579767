import React from 'react';
import styled from 'styled-components';

const BackgroundTinterWrapper = styled.div`
    position: relative;
    background-color: rgba(0, 0, 0, 0.75);
    position: relative;
    min-height: 100vh;
    overflow-y: auto;
`;

const BackgroundTinter = ({ children }) => {
    return (
        <BackgroundTinterWrapper>
            {children}
        </BackgroundTinterWrapper>
    );
};

export default BackgroundTinter;
