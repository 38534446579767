import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useMatch, useResolvedPath } from 'react-router';

const NavBarSpacer = styled.div`
    height: 60px;
`;

const NavbarContainer = styled.div`
    position: fixed;
    z-index: 100;
    top: 0;
    width: -webkit-fill-available;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    background: #303030;
    justify-content: space-between;
    
    a {
        color: white;
        text-decoration: none;
        padding: 0 10px;
    }
`;

const ScheduleCallButton = styled(Link)`
    background: #69507A;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 8px !important;
    text-decoration: none;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
`;

function CustomLink({ to, children, ...props }) {
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({ path: resolvedPath.pathname, end: true })

    return (
        <Link to={to} {...props} className={isActive ? "active" : ""}>
            {children}
        </Link>
    )
}

const Spreader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1100px;
    margin: auto;
`;

export default function NavBar({isMobile}) {
    return (
        <>
            <NavBarSpacer/>
            <NavbarContainer>
                <Spreader>
                    <div>
                        <div className="navbar">
                            <span>
                                <CustomLink to="/">
                                    Home
                                </CustomLink>
                            </span>
                            <span>
                                <CustomLink to="/about">
                                    About
                                </CustomLink>
                            </span> 
                            <span>
                                <CustomLink to="/services">
                                    Services
                                </CustomLink>
                            </span> 
                            <span>
                                <CustomLink to="/contact">
                                    Contact
                                </CustomLink>
                            </span> 
                        </div>
                    </div>
                    <ScheduleCallButton to='/schedule-call'>
                        Schedule a Call
                    </ScheduleCallButton>
                </Spreader>
            </NavbarContainer>
        </>
    )
}
