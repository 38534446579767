import styled from 'styled-components';
import { PageContainer } from '../components/PageContainer';
import { useEffect } from 'react';

const ServicesPageContainer = styled(PageContainer)`
        max-width: 1100px;
        margin: auto;
    `;

const HeaderImage = styled.img`
    max-width: 700px;
    margin-bottom: 32px;
    width: 100%;
    align-self: baseline;
`;

export default function Services() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    return (
        <ServicesPageContainer>
            <HeaderImage src="/bravo-header.png" alt="bravo" />
            <h1>Our Treatment Approach</h1>
            <p>At Bravo Austin Concierge Physical Therapy, we pride ourselves on offering a specialized treatment approach that blends advanced clinical expertise with personalized care. Our manual physical therapist, who has completed rigorous fellowship training, brings a depth of knowledge and skill to every session, ensuring our patients receive the highest standard of treatment.</p>

            <p>Bravo Austin Concierge Physical Therapy was established with the mission to deliver one-on-one, highly skilled, and personalized treatment, independent of insurance company constraints. We offer in-person visits and bring the treatment directly to you. By providing care in your familiar environment, we ensure convenience and comfort, while also identifying and addressing any environmental factors that may hinder your recovery. This approach enables us to tailor our treatment specifically to your individual needs.</p>

            <h2>Comprehensive Assessment</h2>
            <p>Our process begins with a thorough assessment to understand your unique needs and challenges. This includes:</p>
            <ul>
                <li>Detailed Medical History: We review your medical history and any relevant diagnostic reports to gain a complete understanding of your condition.</li>
                <li>Physical Examination: A hands-on evaluation to assess movement patterns, joint mobility, muscle function, and areas of pain or discomfort.</li>
                <li>Functional Testing: We conduct specific tests to determine how your condition affects your daily activities and overall function.</li>
            </ul>
            
            <h2>Personalized Treatment Plans</h2>
            <p>Based on our comprehensive assessment, we develop a customized treatment plan tailored to your individual needs and goals. Our approach includes:</p>
            <ul>
                <li>Manual Therapy: Utilizing advanced techniques such as joint mobilization, soft tissue manipulation, and myofascial release to alleviate pain, improve mobility, and restore function.</li>
                <li>Exercise Prescription: Creating a targeted exercise program to strengthen muscles, enhance flexibility, and promote long-term health.</li>
                <li>Patient Education: Empowering you with knowledge about your condition, treatment options, and preventive strategies to maintain your progress and prevent future issues.</li>
            </ul>
            <h2>Evidence-Based Techniques</h2>
            <p>Our fellowship-trained therapist integrates the latest evidence-based practices into your treatment. This ensures you benefit from the most effective and up-to-date methods in physical therapy. Our techniques are grounded in scientific research and clinical expertise, allowing us to deliver optimal results.</p>

            <h2>Collaborative Care</h2>
            <p>We believe in a holistic approach to patient care. Our therapist works closely with other healthcare providers, such as your primary care physician, orthopedic specialist, chiropractor, or personal trainer, to ensure a cohesive and coordinated treatment plan. This collaborative effort helps to address all aspects of your health and well-being.</p>

            <h2>Ongoing Support</h2>
            <p>Your journey to recovery doesn't end with the completion of your treatment sessions. We provide ongoing support and follow-up care to ensure you continue to progress and achieve your long-term health goals. Our commitment to your well-being means we're here for you every step of the way.</p>

            <p>Experience the difference that expert manual therapy can make. At Bravo, we are dedicated to helping you live pain-free and achieve your full potential. Contact us today to schedule an appointment and take the first step towards a healthier, more active life.</p>
        </ServicesPageContainer>
    );
}