import React, { useEffect } from 'react';
import styled from 'styled-components';
import { PageContainer } from '../components/PageContainer';

const HeaderImage = styled.img`
    max-width: 700px;
    width: 100%;
`;

const HomePageContainer = styled(PageContainer)`
    max-width: 1100px;
    margin: auto;
`;

const Mission = styled.h1`
    margin: 70px 0 32px;
`;

const MissionBlurb = styled.p`
    line-height: 34.905px;
`;

const HomePage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <HomePageContainer>
            <HeaderImage src="/bravo-header.png" alt="bravo" />
            <Mission>Your Path to Personalized Rehabilitation and Wellness</Mission>
            <MissionBlurb>
                <p>
                    With a commitment to convenience and quality, our concierge physical 
                    therapy service brings rehabilitation to your doorstep. Serving 
                    anyone in need of physical therapy but seeking a more accessible 
                    and personalized experience, we offer expert PT and manual therapy 
                    services at the location of your choice.
                </p>
                <p>
                    Our mission is to prioritize individual care, providing one-on-one 
                    sessions that cater to your schedule, ensuring optimal recovery and 
                    well-being. Join us on the journey towards wellness as we bring 
                    the expertise and compassion of physical therapy directly to you, 
                    empowering your health and lifestyle.
                </p>
            </MissionBlurb>
        </HomePageContainer>
    );
};

export default HomePage;
