import styled from "styled-components";
import { PageContainer } from "../components/PageContainer";
import { useEffect } from "react";

const ContactFormContainer = styled(PageContainer)`
    width: 90%;
    max-width: 400px;
    display: inline-block;
    margin: 30px auto;
    border-radius: 8px;
    `;

const CenterAlign = styled.div`
    text-align: center;
    `;

const Title = styled.h1`
    margin-top:0;
    `;


function Contact() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <CenterAlign>
            <ContactFormContainer>
                <Title>Have a question? <br/>Let's get in touch!</Title>
                <p>Email: <a href="mailto:jackie@bravoaustinpt.com">jackie@bravoaustinpt.com</a></p>
                <p>Instagram: <a href="https://www.instagram.com/bravoaustinpt/" target="_blank" rel="noreferrer"> @bravoaustinpt</a></p>
                <p>Phone: 512-222-7787</p>
                <p>Fax: 512-566-3042</p>
                <p>Service Area: Austin and the surrounding Northwest Austin area</p>
            </ContactFormContainer>
        </CenterAlign>
    );
}

export default Contact;
