import './App.css';
import Navbar from './components/Navbar';
import { Routes, Route } from 'react-router-dom';
import { useState, useEffect } from 'react';
import HomePage from './pages/Home';
import AboutPage from './pages/About';
import ServicesPage from './pages/Services';
import ContactPage from './pages/Contact';
import ScheduleACallPage from './pages/ScheduleACall';
import BackgroundTinter from './components/BackgroundTinter';
import FooterLogo from './components/FooterLogo';
import Footer from './components/Footer';
import styled from 'styled-components';

const GoldBorder = styled.div`
  border: 1px solid #e0aa3e;
  padding: 20px;
  margin: 30px auto;
  background: #101010;
  width: 90%;
  max-width: 1100px;
  animation: fadeIn 2.5s;
  box-shadow: 0px 0px 10px 2px #e0aa3e;
`;

function App() {
  // eslint-disable-next-line no-unused-vars
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 800) {
      setIsMobile(true);
    }
  }, []);

  return (
    <div className="App">
      <BackgroundTinter>
      <Navbar/>
      <GoldBorder>
        <Routes >
            <Route path="/" element={<HomePage/>}/>
            <Route path="/about" element={<AboutPage isMobile={isMobile}>About</AboutPage>}/>
            <Route path="/contact" element={<ContactPage>Contact</ContactPage>}/>
            <Route path="/services" element={<ServicesPage>Services</ServicesPage>}/>
            <Route path="/schedule-call" element={<ScheduleACallPage>Schedule a Call</ScheduleACallPage>}/>
            </Routes>
          <FooterLogo/>
      </GoldBorder>
      <Footer/>
      </BackgroundTinter>
    </div>
  );
}

export default App;
